import React from 'react';
import { graphql,} from 'gatsby';
import RichText from "../components/richText";
import SEO from '../components/seo';
import { default as PageEl } from '../components/common/layouts/therapy';
import Button from "../components/common/button";
import Image from "../components/prismicImage";

export const query = graphql`
	query TherapyQuery($id: String) {
		prismic {
			allTherapys(id: $id) {
				edges {
					node {
						seo_title
						seo_desciption
						heading
						subheading
						image
						description
					}
				}
			}
		}
	}
`;

const Therapy = (props) => {
	const data = props?.data?.prismic?.allTherapys?.edges[0]?.node;
	if(!data){
		return null;
	}	
	return (
		<>
			<SEO title={props.data.prismic.allTherapys.edges[0].node.seo_title}/>
			<PageEl>
				<div className="info">
					<Button className="sm" link={"/therapy"} text={"back to therapies"}></Button>
					<div className="content">
					{!! data.heading && <RichText render={data.heading}/>}
					{!! data.subheading && <RichText render={data.subheading}/>}
					{!! data.description && <RichText render={data.description}/>}
					{!! data.heading && <Button className="center md" link={"/book"} text={"Book Now!"} stateInfo={{booking: data.heading[0].text}}></Button>}
					</div>
				</div>
				<div className="sidebar">
					{!! data.image && <Image src={data.image.url} alt={data.heading} sizes={[100,200,300,400,500]} quality={50}/>}
				</div>
			</PageEl>
		</>
	);
};

export default Therapy;
